<div class="modal-header">
	<h1 class="modal-title">Advanced Search</h1>
	<ae-modal-mover />
	<button
		class="btn btn-outline-dark"
		id="ac.notes.modal.component.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<ng-container *aeSpinWhile="loading">
		<div [formGroup]="formGroup">
			<div class="form-group">
				<label for="advanced.search.modal.component.program">Program</label>
				<input
					aeInputTrim
					autocomplete="off"
					class="form-control"
					formControlName="program"
					id="advanced.search.modal.component.program"
					type="text"
				/>
			</div>
			<div class="form-group">
				<label for="advanced.search.modal.component.parent_program"
					>Parent Program</label
				>
				<input
					aeInputTrim
					autocomplete="off"
					class="form-control"
					formControlName="parent_program"
					id="advanced.search.modal.component.parent_program"
					type="text"
				/>
			</div>
			<div class="form-group">
				<label for="advanced.search.modal.component.program_type"
					>Program Type</label
				>
				<ng-select
					bindLabel="short_code"
					bindValue="short_code"
					formControlName="program_type"
					labelForId="advanced.search.modal.component.program_type"
					[items]="programTypeOptions"
					[multiple]="true"
				/>
			</div>
			<div class="form-group">
				<label for="advanced.search.modal.component.state">State</label>
				<ng-select
					bindLabel="key"
					bindValue="key"
					formControlName="state"
					labelForId="advanced.search.modal.component.state"
					[items]="stateOptions"
					[multiple]="true"
				/>
			</div>
		</div>

		@if (!multi) {
			<div>
				<div class="fw-bold mb-2">Results</div>
				<ul
					aria-labelledby="found_organization"
					class="results-container list-group"
					role="listbox"
					tabindex="0"
					title="Found Organization List"
					(keydown)="onKeydown($event, 'single')"
				>
					@for (org of foundOrganizations; track org) {
						<li
							class="d-flex justify-content-between p-1 result pointer"
							role="option"
							tabindex="-1"
							[attr.aria-selected]="selectedSearchOrgId === org.id"
							[id]="org.id"
							[ngClass]="
								selectedSearchOrgId === org.id
									? 'bg-info text-white selected'
									: ''
							"
							(click)="selectSearchOrg(org)"
							(keyup.enter)="selectSearchOrg(org)"
						>
							<ng-container
								*ngTemplateOutlet="orgDisplay; context: { $implicit: org }"
							/>
						</li>
					}
				</ul>
			</div>
		} @else {
			<div class="d-flex pt-3">
				<div class="w-50 me-2">
					<div class="d-flex justify-content-between">
						<div class="fw-bold">
							Results ({{ foundOrganizations?.length }})
						</div>
						<button
							class="btn btn-sm me-1 text-info"
							title="Add to Included"
							type="button"
							[disabled]="canAddToIncluded(foundOrganizations)"
							(click)="migrateOver('include')"
						>
							<i aria-hidden="true" class="fas fa-chevrons-right"></i>
						</button>
					</div>
					<ul
						aria-labelledby="found_organization"
						class="results-container list-group"
						role="listbox"
						tabindex="0"
						title="Found Organization List"
						(keydown)="onKeydown($event, 'search')"
					>
						@for (org of foundOrganizations; track org) {
							<li
								class="d-flex justify-content-between p-1 result"
								role="option"
								[attr.aria-selected]="selectedSearchOrgId === org.id"
								[id]="org.id"
								[ngClass]="
									selectedSearchOrgId === org.id
										? 'bg-info text-white selected'
										: ''
								"
							>
								<ng-container
									*ngTemplateOutlet="orgDisplay; context: { $implicit: org }"
								/>
								<button
									class="btn btn-sm me-1 text-info"
									tabindex="-1"
									title="Add to Included"
									type="button"
									[disabled]="canAddToIncluded()"
									(click)="migrateOver('include', org)"
								>
									<i aria-hidden="true" class="fas fa-chevron-right"></i>
								</button>
							</li>
						}
					</ul>
				</div>
				<div class="w-50">
					<div class="d-flex justify-content-between">
						<div class="fw-bold">
							Included ({{ includedOrganizations.length }})
						</div>
						<button
							class="btn btn-sm me-1 text-info"
							title="Remove from Included"
							type="button"
							(click)="migrateOver('remove')"
						>
							<i aria-hidden="true" class="fas fa-chevrons-left"></i>
						</button>
					</div>
					<ul
						aria-labelledby="included_organization"
						class="results-container list-group"
						role="listbox"
						tabindex="0"
						title="Included Organization List"
						(keydown)="onKeydown($event, 'included')"
					>
						@for (org of includedOrganizations; track org) {
							<li
								class="d-flex justify-content-between p-1 result"
								role="option"
								[attr.aria-selected]="selectedIncludedOrgId === org.id"
								[id]="org.id"
								[ngClass]="
									selectedIncludedOrgId === org.id
										? 'bg-info text-white selected'
										: ''
								"
							>
								<ng-container
									*ngTemplateOutlet="orgDisplay; context: { $implicit: org }"
								/>
								<button
									class="btn btn-sm me-1 text-info"
									tabindex="-1"
									title="Add to Results"
									type="button"
									(click)="migrateOver('remove', org)"
								>
									<i aria-hidden="true" class="fas fa-chevron-left"></i>
								</button>
							</li>
						}
					</ul>
				</div>
			</div>
		}
	</ng-container>
</div>
<div class="modal-footer">
	<button
		class="btn btn-default"
		id="advanced.search.modal.component.footer.close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Cancel
	</button>
	<button
		class="btn btn-primary"
		id="advanced.search.modal.component.footer.update"
		type="button"
		(click)="accept()"
	>
		Update
	</button>
</div>
<ng-template #orgDisplay let-org>
	<div>
		{{ org.title }}
		<div class="fs-sm">{{ orgSubtitle(org) }}</div>
	</div>
</ng-template>
