import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StudentCourseFeedbackModel } from 'src/lib/services/api/students/classes/student-course-feedback.model';
import { StudentCourseService } from 'src/lib/services/api/students/classes/student-course.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseFeedbackStoreService {
	private _recentFeedbackCache = new BehaviorCache<
		number,
		StudentCourseFeedbackModel[]
	>(
		(uid) => this.courseService.getFeedback(uid),
		'StudentCourseFeedbackStore RecentFeedbackCache',
		() => [],
		60 * 5,
		true,
	);

	private _registrationFeedbackCache = new BehaviorCache<
		[number, number],
		StudentCourseFeedbackModel
	>(
		([uid, registrationId]) =>
			this.courseService.getCourseFeedback(uid, registrationId),
		'StudentCourseFeedbackStore RegistrationFeedbackCache',
		() => null,
		60 * 5,
		true,
	);

	constructor(private courseService: StudentCourseService) {}

	/**
	 * Feedback
	 */
	public recentFeedback$ = (
		uid: number,
	): Observable<StudentCourseFeedbackModel[]> => {
		return this._recentFeedbackCache.getCache(uid);
	};

	public refreshRecentFeedback = (
		uid: number,
		forceRefresh: boolean = true,
	) => {
		return this._recentFeedbackCache.fetchData(uid, forceRefresh);
	};

	public clearRecentFeedback = (uid: number) => {
		return this._recentFeedbackCache.clearData(uid);
	};

	/**
	 * Registration Feedback
	 */
	public registrationFeedback$ = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseFeedbackModel> => {
		return this._registrationFeedbackCache.getCache([uid, registrationId]);
	};

	public refreshRegistrationFeedback = (
		uid: number,
		registrationId: number,
		forceRefresh: boolean = true,
	) => {
		return this._registrationFeedbackCache.fetchData(
			[uid, registrationId],
			forceRefresh,
		);
	};

	public clearRegistrationFeedback = (uid: number, registrationId: number) => {
		return this._registrationFeedbackCache.clearData([uid, registrationId]);
	};

	/**
	 * Mark Feedback
	 */
	public markFeedbackRead$ = (
		uid: number,
		registrationId: number,
		submissionId: number,
	) => {
		return this.courseService
			.markFeedbackRead(uid, registrationId, [submissionId])
			.pipe(
				tap(() => {
					this.refreshRecentFeedback(uid, false);
					this.refreshRegistrationFeedback(uid, registrationId, true);
				}),
			);
	};
}
