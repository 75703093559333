// memoryStorage is a useful last fallback to ensure that the store
// is functions (meaning store.get(), store.set(), etc will all function).
// However, stored values will not persist when the browser navigates to
// a new page or reloads the current page.

module.exports = {
  name: 'memoryStorage',
  read: read,
  write: write,
  each: each,
  remove: remove,
  clearAll: clearAll
};
var memoryStorage = {};
function read(key) {
  return memoryStorage[key];
}
function write(key, data) {
  memoryStorage[key] = data;
}
function each(callback) {
  for (var key in memoryStorage) {
    if (memoryStorage.hasOwnProperty(key)) {
      callback(memoryStorage[key], key);
    }
  }
}
function remove(key) {
  delete memoryStorage[key];
}
function clearAll(key) {
  memoryStorage = {};
}