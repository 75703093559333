module.exports = defaultsPlugin;
function defaultsPlugin() {
  var defaultValues = {};
  return {
    defaults: defaults,
    get: get
  };
  function defaults(_, values) {
    defaultValues = values;
  }
  function get(super_fn, key) {
    var val = super_fn();
    return val !== undefined ? val : defaultValues[key];
  }
}