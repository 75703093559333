import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class AbDrillDownTeacherFeedbackModel {
	teacher: string;
	student_uid: number;
	student_name: string;
	registration_title: string;
	assignment_title: string;
	@Expose({ name: 'submitted_timestamp' })
	@Transform(dateTransformer)
	submitted_date: Date;
	@Expose({ name: 'scored_timestamp' })
	@Transform(dateTransformer)
	scored_date: Date;
	score: number;
	feedback: string[];
}

export function AbDrillDownTeacherFeedbackModel_ClassTransformType() {
	return AbDrillDownTeacherFeedbackModel;
}

export class AbDrillDownTeacherFeedbackResponseModel {
	total: number;
	@Type(AbDrillDownTeacherFeedbackModel_ClassTransformType)
	teacher_feedback: AbDrillDownTeacherFeedbackModel[];
}

export interface AbDrillDownTeacherFeedbackSearchArgument {
	student_name: PagingSearchFieldArgument;
	registration_title: PagingSearchFieldArgument;
	submitted_timestamp: PagingSearchFieldArgument;
	scored_timestamp: PagingSearchFieldArgument;
	score: PagingSearchFieldArgument;
}
