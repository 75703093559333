<div
	#popover="ngbPopover"
	container="body"
	placement="bottom"
	triggers="hover focus"
	[ngbPopover]="hasErrors() ? errors : null"
>
	<ng-content />
</div>

<ng-template #errors>
	@for (error of ctrl.errors | keyvalue; track error.key) {
		<div class="text-danger">
			@if (errorMessages[error.key] != null) {
				{{ errorMessages[error.key] }}
			} @else {
				@switch (error.key) {
					@case ('required') {
						Required
					}
					@case ('minlength') {
						This field must be at least
						{{ error.value.requiredLength }} characters long
					}
					@case ('maxlength') {
						This field must be no more than
						{{ error.value.requiredLength }} characters long
					}
					@case ('pattern') {
						This field must match the pattern: {{ error.value.requiredPattern }}
					}
					@case ('min') {
						Minimum value allowed is {{ error.value.min }}
					}
					@case ('max') {
						Maximum value allowed is {{ error.value.max }}
					}
					@default {
						{{ convertObject(error.value, error.key) }}
					}
				}
			}
		</div>
	}
</ng-template>
