import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
	AsyncSubject,
	Observable,
	combineLatest,
	debounceTime,
	takeUntil,
} from 'rxjs';
import { ProgramTypeOptionModel } from 'src/lib/services/api/generic/options/program-type-option.model';
import { StateOptionModel } from 'src/lib/services/api/generic/options/state-option.model';
import { OrganizationModel } from 'src/lib/services/api/organizations/organization.model';
import { OptionsStoreService } from 'src/lib/services/stores/options/options-store.service';
import { OrganizationsStoreService } from 'src/lib/services/stores/organizations-store/organizations-store.service';
import { hasValue, isArray } from 'src/lib/utilities/compare';
import { firstBy } from 'thenby';
import { Key } from 'ts-key-enum';
import { InputTrimDirective } from '../../../templates/global/email-trim/input-trim.directive';
import { ModalMoverComponent } from '../../../templates/global/modal-mover/modal-mover.component';
import { SpinWhileDirective } from '../../../templates/layout/spin-while/spin-while.directive';

interface AdvancedSearchFormGroup {
	program: FormControl<string>;
	parent_program: FormControl<string>;
	program_type: FormControl<string[]>;
	state: FormControl<string[]>;
}

@Component({
	selector: 'ae-program-advanced-search-modal',
	templateUrl: './program-advanced-search-modal.component.html',
	styleUrls: ['./program-advanced-search-modal.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		InputTrimDirective,
		ModalMoverComponent,
		NgClass,
		NgSelectModule,
		NgTemplateOutlet,
		ReactiveFormsModule,
		SpinWhileDirective,
	],
})
export class ProgramAdvancedSearchModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	@Input() multi: boolean = false;
	@Input() organizations$: Observable<number[]>;
	@Input() maxSelectedItems: number;
	@Input() includeStructural: boolean = false;

	public comment: string;
	public errorLoading = false;
	public loading = true;

	public stateOptions: StateOptionModel[];
	public programTypeOptions: ProgramTypeOptionModel[];
	public organizations: OrganizationModel[] = [];
	public foundOrganizations: OrganizationModel[] = [];
	public includedOrganizations: OrganizationModel[] = [];

	public formGroup: FormGroup<AdvancedSearchFormGroup>;

	protected selectedSearchOrgId: number;
	protected selectedIncludedOrgId: number;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
		private optionsStoreService: OptionsStoreService,
		private organizationStoreService: OrganizationsStoreService,
	) {}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		multi: boolean;
		organizations$: Observable<number[]>;
		includeDeactivated: boolean;
		selectedOrgs?: number[];
		maxSelectedItems?: number;
	}): void => {
		// SET DATA
		this.multi = data?.multi;
		this.organizations$ = data?.organizations$;
		this.maxSelectedItems = data?.maxSelectedItems;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		combineLatest([
			this.optionsStoreService.states$(),
			this.optionsStoreService.types$(),
			this.organizations$,
			this.organizationStoreService.organizations$(data.includeDeactivated),
		])
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: ([states, types, orgOptions, availableOrgs]) => {
					this.stateOptions = states;
					this.programTypeOptions = types;
					this.organizations = availableOrgs
						.filter((o) => orgOptions.find((oo) => oo === o.id))
						.sort(firstBy((x) => x.title));

					if (data.selectedOrgs?.length > 0) {
						this.includedOrganizations = this.organizations.filter((o) =>
							data.selectedOrgs.includes(o.id),
						);
						this.foundOrganizations = this.organizations.filter(
							(o) => !data.selectedOrgs.includes(o.id),
						);
					} else {
						this.foundOrganizations = this.organizations;
					}
				},
			});

		this.formGroup = this.fb.group<AdvancedSearchFormGroup>({
			program: new FormControl(),
			parent_program: new FormControl(),
			program_type: new FormControl(),
			state: new FormControl(),
		});

		this.formGroup.valueChanges
			.pipe(debounceTime(250), takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (
					Object.values(val).find(
						(v) => (!isArray(v) && hasValue(v)) || v?.length > 0,
					)
				) {
					this.foundOrganizations = this.organizations.filter(
						(o) => !this.includedOrganizations.includes(o),
					);
					let modelProperty = null;
					Object.keys(val).forEach((k) => {
						if (val[k] && val[k].length > 0) {
							modelProperty = k;
							if (k === 'program') {
								modelProperty = 'title';
							}
							if (k === 'parent_program') {
								modelProperty = 'parent_title';
							}
							if (k === 'program_type') {
								modelProperty = 'itype_shortcode';
							}

							if (k === 'program' || k === 'parent_program') {
								this.foundOrganizations = this.foundOrganizations.filter((o) =>
									o[modelProperty]
										?.toLocaleLowerCase()
										.includes(val[k].toLocaleLowerCase()),
								);
							}
							if (k === 'program_type' || k === 'state') {
								this.foundOrganizations = this.foundOrganizations.filter((o) =>
									val[k].includes(o[modelProperty]),
								);
							}
						}
					});
				} else {
					this.foundOrganizations = this.organizations.sort(
						firstBy((x) => x.title),
					);
				}

				this.foundOrganizations = this.foundOrganizations.sort(
					firstBy((x) => x.title),
				);
			});

		this.loading = false;
	};

	protected migrateOver = (
		direction: 'include' | 'remove',
		item?: OrganizationModel,
	) => {
		switch (direction) {
			case 'include':
				if (item) {
					this.foundOrganizations.splice(
						this.foundOrganizations.indexOf(item),
						1,
					);
					if (!this.includedOrganizations.includes(item)) {
						this.includedOrganizations.push(item);
					}
				} else {
					this.foundOrganizations?.forEach((o) => {
						if (!this.includedOrganizations.includes(o)) {
							this.includedOrganizations.push(o);
						}
					});

					this.foundOrganizations = [];
				}
				break;
			case 'remove':
				if (item) {
					this.includedOrganizations.splice(
						this.includedOrganizations.indexOf(item),
						1,
					);
					this.foundOrganizations.push(item);
				} else {
					//move everything from included into results column
					this.includedOrganizations.forEach((o) => {
						this.foundOrganizations.push(o);
					});
					this.includedOrganizations = [];
				}
				this.foundOrganizations = this.foundOrganizations.sort(
					firstBy((x) => x.title),
				);
		}
	};

	protected selectSearchOrg = (org: OrganizationModel) => {
		this.selectedSearchOrgId = org.id;
		this.scrollIntoView(org.id);
	};

	protected canAddToIncluded = (orgs?: OrganizationModel[]) => {
		const max = this.maxSelectedItems ?? Number.MAX_SAFE_INTEGER;

		if (max > 0) {
			if (orgs) {
				const length =
					orgs.filter((o) => !this.includedOrganizations.includes(o))?.length +
					this.includedOrganizations.length;
				if (length <= max) {
					return false;
				}
				return true;
			} else {
				if (this.includedOrganizations.length < max) {
					return false;
				}
				return true;
			}
		}
		return false;
	};

	public onKeydown = (
		event: KeyboardEvent,
		source: 'search' | 'included' | 'single',
	): void => {
		event?.preventDefault();
		event?.stopPropagation();

		if (source === 'single') {
			if (!this.selectedSearchOrgId) {
				this.selectedSearchOrgId = this.foundOrganizations[0]?.id;
			}
			const index = this.foundOrganizations.findIndex((o) => {
				return o.id === this.selectedSearchOrgId;
			});
			if (event.key === Key.ArrowUp) {
				if (index > 0) {
					this.selectedSearchOrgId = this.foundOrganizations[index - 1]?.id;
				} else {
					this.selectedSearchOrgId = this.foundOrganizations[0]?.id;
				}
			}
			if (event.key === Key.ArrowDown) {
				if (index > -1 && index < this.foundOrganizations.length - 2) {
					this.selectedSearchOrgId = this.foundOrganizations[index + 1]?.id;
				} else {
					this.selectedSearchOrgId =
						this.foundOrganizations[this.foundOrganizations.length - 1]?.id;
				}
			}
			this.scrollIntoView(this.selectedSearchOrgId);
		} else {
			if (source === 'search') {
				if (!this.selectedSearchOrgId) {
					this.selectedSearchOrgId = this.foundOrganizations[0]?.id;
				}
			}
			if (source === 'included') {
				if (!this.selectedIncludedOrgId) {
					this.selectedIncludedOrgId = this.includedOrganizations[0]?.id;
				}
			}

			if (event.key === Key.ArrowUp) {
				if (source === 'search') {
					const index = this.foundOrganizations.findIndex((o) => {
						return o.id === this.selectedSearchOrgId;
					});
					if (index > 0) {
						this.selectedSearchOrgId = this.foundOrganizations[index - 1]?.id;
					} else {
						this.selectedSearchOrgId = this.foundOrganizations[0]?.id;
					}
					this.scrollIntoView(this.selectedSearchOrgId);
				}
				if (source === 'included') {
					const index = this.includedOrganizations.findIndex((o) => {
						return o.id === this.selectedIncludedOrgId;
					});
					if (index > -1) {
						this.selectedIncludedOrgId =
							this.includedOrganizations[index - 1]?.id;
					} else {
						this.selectedIncludedOrgId = this.includedOrganizations[0]?.id;
					}
					this.scrollIntoView(this.selectedIncludedOrgId);
				}
			} else if (event.key === Key.ArrowDown) {
				if (source === 'search') {
					const index = this.foundOrganizations.findIndex((o) => {
						return o.id === this.selectedSearchOrgId;
					});
					if (index < this.foundOrganizations.length) {
						this.selectedSearchOrgId = this.foundOrganizations[index + 1]?.id;
					} else {
						this.selectedSearchOrgId = this.foundOrganizations[0]?.id;
					}
					this.scrollIntoView(this.selectedSearchOrgId);
				}
				if (source === 'included') {
					const index = this.includedOrganizations.findIndex((o) => {
						return o.id === this.selectedIncludedOrgId;
					});
					if (index < this.includedOrganizations.length) {
						this.selectedIncludedOrgId =
							this.includedOrganizations[index + 1]?.id;
					} else {
						this.selectedIncludedOrgId = this.includedOrganizations[0]?.id;
					}
					this.scrollIntoView(this.selectedIncludedOrgId);
				}
			} else if (event.key === Key.Enter) {
				if (source === 'search') {
					const index = this.foundOrganizations.findIndex((o) => {
						return o.id === this.selectedSearchOrgId;
					});
					this.migrateOver('include', this.foundOrganizations[index]);
					if (this.foundOrganizations.length < 1) {
						this.selectedSearchOrgId = null;
					} else if (index === this.foundOrganizations.length) {
						this.selectedSearchOrgId = this.foundOrganizations[index - 1]?.id;
					}
					this.scrollIntoView(this.selectedSearchOrgId);
				}
				if (source === 'included') {
					const index = this.includedOrganizations.findIndex((o) => {
						return o.id === this.selectedIncludedOrgId;
					});
					this.migrateOver('remove', this.includedOrganizations[index]);
					if (this.foundOrganizations.length < 1) {
						this.selectedIncludedOrgId = null;
					} else if (index === this.includedOrganizations.length) {
						this.selectedIncludedOrgId =
							this.includedOrganizations[index - 1]?.id;
					}
					this.scrollIntoView(this.selectedIncludedOrgId);
				}
			}
		}
	};

	private scrollIntoView = (id: string | number) => {
		document.getElementById(id.toString()).scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	};

	protected orgSubtitle = (org: OrganizationModel) => {
		const arr = [];
		arr.push(org.state);
		arr.push(org.itype_shortcode);
		arr.push(org.parent_title);
		return arr.filter((v) => v != null).join(' - ');
	};

	protected accept = () => {
		const val = this.multi
			? this.includedOrganizations.map((o) => o.id)
			: this.selectedSearchOrgId;
		this.activeModal.close(val);
	};
}
