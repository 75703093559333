import { Type } from 'class-transformer';

export class RoleManagerTeamMemberModel {
	uid: number;
	name: string;
}

export function RoleManagerTeamMemberModel_ClassTransform_Type() {
	return RoleManagerTeamMemberModel;
}

export class RoleManagerTeamInstituteModel {
	institute_id: number;
	title: string;
}

export function RoleManagerTeamInstituteModel_ClassTransform_Type() {
	return RoleManagerTeamInstituteModel;
}

export class RoleManagerTeamModel {
	id: number;
	name: string;
	linked_type: string;
	@Type(RoleManagerTeamMemberModel_ClassTransform_Type)
	members?: RoleManagerTeamMemberModel[];
	@Type(RoleManagerTeamInstituteModel_ClassTransform_Type)
	institutes?: RoleManagerTeamInstituteModel[];
}

export class RoleManagerMemberOptionModel {
	uid: number;
	name: string;
	timezone: string;
	total_load?: number;
	active_load?: number;
	is_active?: boolean;
}

export function RoleManagerMemberOptionModel_ClassTransform_Type() {
	return RoleManagerMemberOptionModel;
}

export interface RoleManagerTeamUpsertArgument {
	name: string;
}

export enum RoleManagerRoles {
	academic_coach = 'academic_coach',
	local_advocate = 'local_advocate',
	credentialed_responder = 'credentialed_responder',
	grad_coordinator = 'grad_coordinator',
	testing_coordinator = 'testing_coordinator',
	reentry_coordinator = 'reentry_coordinator',
	reentry_coach = 'reentry_coach',
	case_manager = 'case_manager',
}
