import { Type } from 'class-transformer';

export class LearningPlanRequirementSubjectOptionModel {
	subject_code: string;
	subject_name: string;
}

export function LearningPlanRequirementSubjectOptionModel_ClassTransform_Type() {
	return LearningPlanRequirementSubjectOptionModel;
}

export class LearningPlanRequirementCourseOptionModel {
	course_code: string;
	course_name: string;
	@Type(LearningPlanRequirementSubjectOptionModel_ClassTransform_Type)
	subject_areas: LearningPlanRequirementSubjectOptionModel[];
}

export function LearningPlanRequirementCourseOptionModel_ClassTransform_Type() {
	return LearningPlanRequirementCourseOptionModel;
}

export class LearningPlanRequirementOptionsModel {
	@Type(LearningPlanRequirementSubjectOptionModel_ClassTransform_Type)
	subject_areas: LearningPlanRequirementSubjectOptionModel[];
	@Type(LearningPlanRequirementCourseOptionModel_ClassTransform_Type)
	courses: LearningPlanRequirementCourseOptionModel[];
}
