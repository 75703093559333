import { Type } from 'class-transformer';

export class LearningPlanAlternateSectionModel {
	class_id: string;
	credits: number;
	requires_parental_consent: boolean;
	section_id: number;
	subject_area: string;
	title: string;
}

export function LearningPlanAlternateSectionModel_ClassTransform_Type() {
	return LearningPlanAlternateSectionModel;
}

export class LearningPlanAlternateSectionResponseModel {
	@Type(LearningPlanAlternateSectionModel_ClassTransform_Type)
	alternative_sections: LearningPlanAlternateSectionModel[];
	course_code: string;
}

export interface LearningPlanAlternateSectionArgument {
	subject_area: string;
	course_code: string;
}
