import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	MeetupMinutesModel,
	MeetupMinutesModel_ClassTransform_Type,
} from '../organizations/meetups/meetup-minutes.model';
import { ReEntryStatus } from '../students/re-entry-request/re-entry-request.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class ReentryCoachTrackerClassPacingModel {
	@Transform(dateTransformer)
	start_date: Date;
	weeks: number;
}

export class ReentryCoachTrackerClassModel {
	id: number;
	user_link_id: number;
	title: string;
	assignment_count: number;
	assignment_completed: number;
	@Expose({ name: 'success' })
	grade: number;
	progress: number;
	notes: string;
	@Transform(dateTransformer)
	end_date: Date;
	@Type(ReentryCoachTrackerClassPacingModel_ClassTransform_Type)
	pacing: ReentryCoachTrackerClassPacingModel[];
	registration_type: string;
	@Expose({ name: 'success_zero' })
	grade_zero: number;
	lms: string;
}

export class ReentryCoachTrackerItemModel {
	@Expose({ name: 'student_cohort_year' })
	cohort_year: number;
	uid: number;
	user_link_id: number;
	@Transform(dateTransformer)
	student_last_access: Date;
	institute_id: number;
	@Expose({ name: 'student_timezone' })
	timezone: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	@Expose({ name: 'student_first_name' })
	profile_first_name: string;
	@Expose({ name: 'student_preferred_name' })
	profile_preferred_name: string;
	@Expose({ name: 'student_last_name' })
	profile_last_name: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	institute_state: string;
	institute_type: string;
	@Expose({ name: 'institute_title' })
	program_title: string;
	institute_parent_id: number;
	@Expose({ name: 'institute_parent_title' })
	program_parent_title: string;
	@Expose({ name: 'institute_is_1418' })
	is_1418: number;
	@Type(MeetupMinutesModel_ClassTransform_Type)
	meetup_minutes: MeetupMinutesModel;
	@Type(ReentryCoachTrackerClassModel_ClassTransform_Type)
	classes: ReentryCoachTrackerClassModel[];
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	academic_coach_name: string;
	academic_coach_id: number;
	local_advocate_name: string;
	local_advocate_id: number;
	reentry_request_status: ReEntryStatus;
	@Transform(dateTransformer)
	reentry_requested_on: Date;
	@Expose({ name: 'student_mail' })
	mail: string;
	@Transform(dateTransformer)
	star_math: Date;
	@Transform(dateTransformer)
	star_reading: Date;
	@Transform(dateTransformer)
	assigned_at: Date;
	weekly_assignments: number;
	tags: string[];
	counted_classes: number;
	persistent_courses: number;
}

export function ReentryCoachTrackerClassModel_ClassTransform_Type() {
	return ReentryCoachTrackerClassModel;
}
export function ReentryCoachTrackerClassPacingModel_ClassTransform_Type() {
	return ReentryCoachTrackerClassPacingModel;
}
