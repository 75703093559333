import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProgramAdvancedSearchModalComponent } from './program-advanced-search-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ProgramAdvancedSearchModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(
		multi: boolean,
		organizations$: Observable<number[]>,
		includeDeactivated: boolean,
		selectedOrgs?: number[],
		maxSelectedItems?: number,
	): Observable<number[] | number> {
		return new Observable<number[] | number>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: 'lg',
				scrollable: true,
			};
			const modal = this.modalService.open(
				ProgramAdvancedSearchModalComponent,
				ngbModalOptions,
			);

			(
				modal.componentInstance as ProgramAdvancedSearchModalComponent
			).bindModalData({
				multi: multi,
				organizations$: organizations$,
				includeDeactivated: includeDeactivated,
				selectedOrgs: selectedOrgs,
				maxSelectedItems: maxSelectedItems,
			});

			modal.result
				.then((ids) => {
					o.next(ids);
					o.complete();
				})
				.catch(() => {
					o.complete();
				});
		});
	}
}
