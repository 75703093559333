import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { LearningPlanEditCreditsRequiredArgument } from './learing-plan-edit-credits-required.argument';
import {
	LearningPlanAlternateSectionArgument,
	LearningPlanAlternateSectionResponseModel,
} from './learning-plan-alternate-section.model';
import { LearningPlanEditSectionArgument } from './learning-plan-edit-section.argument';
import { LearningPlanHistoryModel } from './learning-plan-history.model';
import { LearningPlanLockArgument } from './learning-plan-lock.argument';
import { LearningPlanRequirementOptionsModel } from './learning-plan-requirement-options.model';
import {
	LearningPlanCourseSectionModel,
	LearningPlanModel,
} from './learning-plan.model';

@Injectable({
	providedIn: 'root',
})
export class LearningPlanService {
	constructor(private httpClient: HttpClient) {}

	public getLearningPlan = (uid: number): Observable<LearningPlanModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/learning_plan?_format=json`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							LearningPlanModel,
							r.learning_plan as LearningPlanModel,
						),
					{
						errorCode: '61958CCD',
					},
				),
			);
	};

	public downloadPDF = (uid: number): Observable<any> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/learning_plan/download`, {
				headers: { Accept: 'application/pdf' },
				responseType: 'blob' as 'json',
			})
			.pipe(
				mapResponse(
					(r) =>
						new File([r], `learningPlan${new Date()}.pdf`, {
							type: 'application/pdf',
						}),
					{
						errorCode: '30FD9B5E',
					},
				),
			);
	};

	public viewAvailableRequirements = (
		uid: number,
	): Observable<LearningPlanRequirementOptionsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/learning_plan/available_requirements`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(LearningPlanRequirementOptionsModel, r),
					{
						errorCode: '61958CCD',
					},
				),
			);
	};

	public viewAlternativeSections = (
		uid: number,
		args: Partial<LearningPlanAlternateSectionArgument>,
	): Observable<LearningPlanAlternateSectionResponseModel> => {
		const query = buildQueryString(args);

		return this.httpClient
			.get<any>(
				`/api/v1/students/${uid}/learning_plan/alternative_sections${query}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(LearningPlanAlternateSectionResponseModel, r),
					{
						errorCode: '0145A373',
					},
				),
			);
	};

	public viewHistoricalActions = (
		uid: number,
		start_date: string,
		end_date: string,
	): Observable<LearningPlanHistoryModel[]> => {
		let qs = null;
		if (start_date && end_date) {
			qs = buildQueryString({ start_date, end_date });
		}
		return this.httpClient
			.get<any>(
				`/api/v1/students/${uid}/learning_plan/historical_actions${qs != null ? qs : ''}`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							LearningPlanHistoryModel,
							r.historical_actions as any[],
						),
					{
						errorCode: '350BAED5',
					},
				),
			);
	};

	public setLock = (
		uid: number,
		args: LearningPlanLockArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/learning_plan/lock`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '88FECF94',
				}),
			);
	};

	public editCreditsRequired = (
		uid: number,
		args: LearningPlanEditCreditsRequiredArgument,
	): Observable<LearningPlanModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/learning_plan/credits`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							LearningPlanModel,
							r.learning_plan as LearningPlanModel,
						),
					{
						errorCode: 'C07717CA',
					},
				),
			);
	};
	public rebuildPlan = (uid: number): Observable<LearningPlanModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/learning_plan/rebuild`, {})
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							LearningPlanModel,
							r.learning_plan as LearningPlanModel,
						),
					{
						errorCode: '883600BF',
					},
				),
			);
	};

	public editSection = (
		uid: number,
		sectionId: number,
		args: Partial<LearningPlanEditSectionArgument>,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/learning_plan/section/${sectionId}`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'C07717CA',
				}),
			);
	};

	public addSection = (
		uid: number,
		args: Partial<LearningPlanEditSectionArgument>,
	): Observable<LearningPlanCourseSectionModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/learning_plan/section`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(LearningPlanCourseSectionModel, r.upcoming_course),
					{
						errorCode: '61C2A5E9',
					},
				),
			);
	};

	public deleteSection = (uid: number, sectionId: number): Observable<any> => {
		return this.httpClient
			.delete<any>(`/api/v1/students/${uid}/learning_plan/section/${sectionId}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '613B4BBF',
				}),
			);
	};
}
